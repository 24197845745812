import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styles/media'

const Center = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const Wrapper = styled.div`
  margin: 120px auto 200px auto;
  width: 700px;
  min-height: calc(100vh - 298px - 298px - 92px);

  ${media.desktop`
  `}

  ${media.tablet`
    width: auto;
    margin: 55px 40px 100px 160px;
    min-height: calc(100vh - 665px - 92px);
  `}

  ${media.phone`
    margin: 0 24px 60px 24px;
  `}
`

const Container = ({ children, ...restProps }) => (
  <Center>
    <Wrapper {...restProps}>{children}</Wrapper>
  </Center>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
