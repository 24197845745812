import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'layouts/Default'
import PageSection from 'components/Section/pageSection'
import Container from 'components/Container/job'
import Section from 'components/Section'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { white } from 'const/colors'
import media from 'styles/media'
import { animatedLinkStyles } from 'styles/animations'
import PageTransitionLink from 'components/PageTransitionLink'
import Helmet from 'react-helmet'

const City = styled.p`
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: ${white};

  ${media.tablet`
    font-size: 16px;
    line-height: 20px;
  `}
`

const Heading = styled.h1`
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: ${white};
  font-size: 36px;
  line-height: 48px;

  ${media.tablet`
    font-size: 24px;
    line-height: 40px;
  `}
`

const Description = styled.div`
  font-size: 20px;
  color: ${white};
  line-height: 28px;

  & > h2 {
    font-size: 24px;
    margin-top: 65px;
  }

  & > ul > li {
    padding-bottom: 15px;
    max-width: 500px;
  }

  ${media.tablet`
    font-size: 18px;
    line-height: 28px;
  `}
`

const Cta = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: ${white};

  & > span {
    font-size: 25px;
    line-height: 50px;
    border-bottom: 1px solid white;
    padding-bottom: 3px;
  }

  ${media.tablet`
    font-size: 16px;
    line-height: 20px;

    & > span {
      font-size: 20px;
      line-height: 50px;
    }
  `}

  ${media.phone`
    margin-top: 45px;
  `}
`

const BackBtn = styled.div`
  position: absolute;
  left: 146px;
  font-size: 20px;
  color: ${white};

  ${media.desktop`
    left: 40px;
  `}

  ${media.tablet`
    font-size: 16px;
  `}

  ${media.phone`
    position: relative;
    left: 0px;
    margin: 50px 0;
  `}
`

const BackStyle = styled.span`
  padding-bottom: 1px;
  ${animatedLinkStyles}

  &::after {
    background: ${white};
  }
`

const JobListing = ({
  data: {
    kenticoCloudItemJob: {
      elements: { location, title, description, cta_text, cta_link },
    },
  },
  ...restProps
}) => {
  return (
    <>
      <Helmet>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'JobPosting',
              url: 'https://distologystudios.com/careers/listing/developer-m-w',
              title: 'Developer (M/W)',
              datePosted: '2019-06-06',
              employmentType: 'FULL_TIME',

              hiringOrganization: {
                '@type': 'Organization',
                name: 'Distology Studios',
                sameAs: 'https://distologystudios.com',
                logo: 'FB logo -> add to server',
              },

              jobLocation: {
                '@type': 'Place',
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: 'Manteuffelstr. 64',
                  addressLocality: ', Berlin',
                  addressRegion: 'Berlin',
                  postalCode: '10999',
                  addressCountry: 'DE',
                },
              },
              image: 'https://distologystudios.com/images/sb_logo.jpg',
              description: `<p>We are looking for an experienced developer to join us in our Berlin office. You will share responsibilities with our technical director and help to advise and lead the existing dev team.</p>
          <p>The role would be suited to someone who has solid years of hands-on development experience (front-end and server-side) who would like to complement this with technical leadership, solution architecture and build process direction.</p>
          <p>Our focus is on latest javascript technologies and delivery methods. Our standards match those of the biggest technical teams and consultancies in the world, whilst preserving the fun and variation that a small agency benefits from. We are looking for the right person to help us keep it this way!&nbsp;.</p>
            <p>We can consider freelancer and P/T roles too (remote is not an option though).</p>
            <br>
            <h2>What we're looking for</h2>
            <ul>
            <li>Excellent full stack developer expertise.</li>
            <li>Particular focus on Javascript, APIs, Mobile (JAMStack).</li>
            <li>Previous digital agency experience would be great.</li>
            <li>Great ability to lead teams, make technical decisions with a focus on customer and delivery quality.</li>
            <li>Solid ability to convert customer requirements into manageable user stories and acceptance criteria.</li>
            <li>Excellent client communication skills, articulating and presenting ideas and solutions.</li>
            <li>Appetite/experience for automated builds and infrastructure (CI/CD, Terraform, Docker, K8S, AWS).</li>
          </ul>`,
            }),
          }}
        />
      </Helmet>
      <Layout
        isColorBackground
        {...restProps}
        headerProps={{ burgerColor: 'white', logoColor: 'white' }}
      >
        <PageSection>
          <Container>
            <BackBtn>
              &larr;
              <PageTransitionLink to="/careers">
                <BackStyle>Careers</BackStyle>
              </PageTransitionLink>
            </BackBtn>
            <Section pb={10}>
              <City>{location.value}</City>
              <Heading>{title.value}</Heading>
            </Section>
            <Description
              dangerouslySetInnerHTML={{ __html: description.value }}
            />
            <Cta>
              {cta_text.value}
              <br />
              <span>
                <a href={`mailto:${cta_link.value}`}>{cta_link.value}</a>
              </span>
            </Cta>
          </Container>
        </PageSection>
      </Layout>
    </>
  )
}

export default JobListing

export const query = graphql`
  query jobListingQuery($slug: String!) {
    kenticoCloudItemJob(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      elements {
        title {
          value
        }
        location {
          value
        }
        cta_text {
          value
        }
        cta_link {
          value
        }
        description {
          value
        }
      }
    }
  }
`

JobListing.propTypes = {
  data: PropTypes.object,
}
